import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import { competences, projects } from '../config'
import Hamburger from './hamburger'

const Menu = ({ className }) => {
  return (
    <>
      <div className={className}>
        <Hamburger />
        <div className='menu-content'>
            <Link className="no-text-dec menu-btn" to="/">
              Home
        </Link>
          {competences.map(competence => {
            const link = '/' + competence
            return (
                <Link key={competence} className="no-text-dec menu-btn" to={link.toLowerCase()}>
                  {competence}
                </Link>
            )
          })}
          <span className='dropdown'>
            <ul className="dropdown-list">
                  <li className='drop-btn'>
              
              <Link className="no-text-dec drop-btn" to="/projects">
                Projecten
              </Link>
              </li>
              <span className="dropdown-content">
              {Object.keys(projects).map(key => {
                let link = `projects/${projects[key].slug}`
                return (
                  <li className='drop-btn'>
                    <Link className="no-text-dec" to={link}>
                      {projects[key].name}
                    </Link>
                  </li>
                )
              })}

                </span>
            </ul>
          </span>
        </div>
      </div>
    </>
  )
}

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default Menu
