export const competences = ['Ontwerpen', 'Onderzoeken', 'Ondernemen', 'Betrokken', 'Ontwikkelen']
export const projects = [{
    'name': 'Script namen herschrijven',
    'slug': 'fqdn-names'
}, {
    'name': 'Documentatie website',
    'slug': 'trainingtool'
}, {
    'name': 'Server aanvraagformulier',
    'slug': 'server-request-form'
}, {
    'name': 'Informatiebladen',
    'slug': 'infosheets'
}, {
    'name': 'Pipeline testen',
    'slug': 'cd-tests'
}, {
    'name': 'Figma plugin',
    'slug': 'figma-plugin',
}, {
    'name': 'Flowchart advies',
    'slug': 'flowchart-advice'
}]