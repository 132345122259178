import React from "react"
import PropTypes from "prop-types"

import Menu from "./menu"
import "../styles/layout.scss"
import "../styles/mobile.scss"
import "../styles/tablet.scss"
import "../styles/desktop.scss"

const Layout = ({ children }) => {

  return (
    <div className='container'>
      <Menu className='sticky menu' />
      <main className='content'>{children}</main>
    </div>
  )
}

export const IndexLayout = ({ children }) => {

  return (
    <div className='container'>
      <main className='index'>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
