import React, { useState, useEffect } from "react"

const Hamburger = () => {

  return (
    <>
      <input type='checkbox' />
      <span></span>
      <span></span>
      <span></span>
    </>
  )
}


export default Hamburger
